import React from 'react'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import { Typography, Grid } from '@material-ui/core'
import { makeStyles, createTheme } from '@material-ui/core/styles'
import { ThemeProvider } from '@material-ui/styles'

const authTheme = createTheme({
  palette: {
    primary: {
      main: '#088B8C',
    },
    secondary: {
      main: '#5F6064',
    },
  },
  typography: {
    fontFamily: ['Inter', 'sans-serif'].join(','),
  },
})

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '2rem',
    height: '80vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  tick: {
    padding: '1rem auto',
    marginBottom: '1rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#219653',
  },
  textWrap: {
    padding: '1rem auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  text: {
    fontSize: '18px',
    lineHeight: '20px',
    fontWeight: '500',
    color: '#2A2F45',
  },
}))

const Error = (props) => {
  const classes = useStyles()

  return (
    <ThemeProvider theme={authTheme}>
      <div className={classes.root}>
        <Grid container>
          <Grid xs={12} sm={12} item className={classes.tick}>
            <CheckCircleIcon style={{ fontSize: 40 }} />
          </Grid>
          <Grid xs={12} sm={12} item className={classes.textWrap}>
            <Typography className={classes.text}>
              Your review has already been given.
            </Typography>
          </Grid>
        </Grid>
      </div>
    </ThemeProvider>
  )
}

export default Error
