import React from 'react'
import Review from 'Containers/Review'
import Error from 'Containers/Error'
import Success from 'Containers/Success'
// import { PrivateRoute } from 'Utils/private-route'
import Loader from './BaseComponents/Loader'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Invalid from 'Containers/Invalid'

const App = () => {
  return (
    <div className="app">
      <BrowserRouter>
        <React.Suspense fallback={<Loader />}>
          <Routes>
            <Route path="/error" element={<Error/>} />
            <Route path="/invalid" element={<Invalid/>} />
            <Route path="/success" element={<Success/>} />
            <Route path="/:id" element={<Review/>} />
            {/* <Redirect from="/" to="/error" /> */}
          </Routes>
        </React.Suspense>
      </BrowserRouter>
    </div>
  )
}

export default App
