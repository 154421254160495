import { checkPin, saveReview ,getIp} from './review.actions'
import { createSlice } from '@reduxjs/toolkit'
const INITIAL_STATE = {
  pinResponse: {},
  reviewResponse: {},
  ipResponse: {},
}

const reviewSlice = createSlice({
  name: 'review',
  initialState: INITIAL_STATE,
  extraReducers: (builder) => {
    builder.addCase(checkPin.fulfilled, (state, action) => {
      state.pinResponse = action.payload
    }),
     builder.addCase(saveReview.fulfilled, (state, action) => {
       state.reviewResponse = action.payload
     }),
      builder.addCase(getIp.fulfilled, (state, action) => {
        state.ipResponse = action.payload
      })
  },
})

const { reducer } = reviewSlice
export default reducer
