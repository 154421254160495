import { configureStore } from '@reduxjs/toolkit'
import reviewReducer from './Review/review.reducer'
import { combineReducers } from 'redux'

const rootReducer = combineReducers({
  review: reviewReducer,
})

const store = configureStore({
  reducer: rootReducer,
})

export default store
