import { createAsyncThunk } from "@reduxjs/toolkit"
import ReviewService from "./review.services";

export const checkPin = createAsyncThunk(
 "review/checkpin",
 async (pin)=>{
 try {
     const res = await ReviewService.verifyPin(pin)
     return res.data
 } catch (error) {
    console.log(error);
 }
 }
)
export const saveReview = createAsyncThunk('review/save', async (data) => {
  try {
    const res = await ReviewService.saveReview(data)
    return res.data
  } catch (error) {
    console.log(error)
  }
})

export const getIp = createAsyncThunk('review/getIp', async () => {
  try {
    const res = await ReviewService.getIpApi()
    return res.data
  } catch (error) {
    console.log(error)
  }
})