import axios from 'axios'
import { Apis } from '../APIs'
const { default: httpCommon } = require('Redux/Http/httpCommon')

class ReviewService {
  verifyPin(pin) {
    return httpCommon.get(`${Apis.Login_verifyPin}/${pin}`)
  }
  saveReview(data) {
    return httpCommon.post(`${Apis.Login_saveReview}`, data)
  }
  getIpApi() {
    return axios.get(`https://geolocation-db.com/json/`)
  }
}

export default new ReviewService()
