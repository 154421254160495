import React from 'react'
import { Typography, Grid } from '@material-ui/core'
import { makeStyles, createTheme } from '@material-ui/core/styles'
import { ThemeProvider } from '@material-ui/styles'
import errorIcon from '../../assets/images/not-found.png'

const authTheme = createTheme({
  palette: {
    primary: {
      main: '#088B8C',
    },
    secondary: {
      main: '#5F6064',
    },
  },
  typography: {
    fontFamily: ['Inter', 'sans-serif'].join(','),
  },
})

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '2rem',
    height: '80vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  iconWrap: {
    marginBottom: '2rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  icon: {
    maxHeight: '80px',
    maxWidth: '80px',
    padding: '1rem auto',
    color: '#8792A2',
  },
  textWrap: {
    padding: '1rem auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  text: {
    fontSize: '18px',
    lineHeight: '20px',
    fontWeight: '500',
    color: '#2A2F45',
    // color: '#8792A2',
  },
}))

const Error = (props) => {
  const classes = useStyles()

  return (
    <ThemeProvider theme={authTheme}>
      <div className={classes.root}>
        <Grid container>
          <Grid xs={12} sm={12} item className={classes.tick}>
            <div className={classes.iconWrap}>
              <img className={classes.icon} src={errorIcon} alt="Not-found" />
            </div>
          </Grid>
          <Grid xs={12} sm={12} item className={classes.textWrap}>
            <Typography className={classes.text}>Oops...something went wrong. Please check if you have the correct link. For further assistance, head to <a href="r.trillium.health">support.trillium.health</a> </Typography>
          </Grid>
        </Grid>
      </div>
    </ThemeProvider>
  )
}

export default Error
